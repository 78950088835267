import Head from "next/head";

function strip(html) {
    var one = html.replace(/<\/?[^>]+(>|$)/gm, "");
    var two = one.replace(/[\r\n]\s*[\r\n]/gm, "");
    return two;
}

const ProductSchema = ({ item, config }) => {

    const org = `{ 
        "@id": "${config.company_name}#organization", 
        "type": "Organization",
        "name": "${config.company_name}", 
        "logo": {
            "@type": "ImageObject",
            "name": "${config.company_name} Logo",
            "width": "200",
            "height": "40",
            "url": "${process.env.NEXT_PUBLIC_LOGO_IMAGE}"
        }
    }`;

    return (
        <Head>
            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org/",
                        "@type": "Product",
                        "name": "${item.name.replace('"', '')}",
                        "image": [
                            "${(item.media && item.media[0] && item.media[0].original_url) ? item.media[0].original_url : ''}"
                        ],
                        "description": "${item.meta_description ? item.meta_description.replace('"', '') : ''}",
                        "mpn": "${item.variations[0].mpn}",
                        "sku": "${item.variations[0].sku}",
                        "offers": {
                            "@type": "Offer",
                            "priceCurrency": "GBP",
                            "price": ${item.variations[0].price_inc_vat / 100},
                            "itemCondition": "https://schema.org/NewCondition",
                            "availability": "${item.variations[0].quantity > 0 ? 'https://schema.org/InStock' : 'https://schema.org/BackOrder' }",
                            "url": "${item.url}"
                        }
                    }
                `}
            </script>
        </Head>
    );
};
export default ProductSchema;





