import dynamic from 'next/dynamic'
import Scripts from '../components/scripts'

const MiniCart = dynamic(() => import('../components/cart/minicart'))
const IndexBody = dynamic(() => import('../components/indexBody'))

import MetaTags from '../components/MetaTags'

function Home({ item, menu, config, featured_products, featured_taxonomies, sliders, latest_blog_post }) {
  
  return (
    <>
      <MetaTags item={item} config={config}></MetaTags>
      <IndexBody item={item} menu={menu} config={config} featured_products={featured_products} featured_taxonomies={featured_taxonomies} sliders={sliders}  latest_blog_post={latest_blog_post}></IndexBody>
      <MiniCart></MiniCart>
      <Scripts javascripts={item.javascripts}></Scripts>
    </>
  )
}


// This function gets called at build time on server-side.
// It may be called again, on a serverless function, if
// revalidation is enabled and a new request comes in
export async function getStaticProps(context) {
  const config_res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/configs')
  const config_data = await config_res.json()
  const config = config_data.data

  const menu_res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/menu')
  const menu = await menu_res.json()

  const res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/landingpage/home')
  const response = await res.json()
  const page = response.data

  const featured_res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/featured-products/')
  const featured_response = await featured_res.json()
  const featured_products = featured_response.data

  const featured_tax_res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/featured-taxonomies/')
  const featured_tax_response = await featured_tax_res.json()
  const featured_taxonomies = featured_tax_response.data

  const sliders_res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/sliders/')
  const sliders_response = await sliders_res.json()
  const sliders = sliders_response.data

  const latest_blog_post_res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/latest-blog')
  const latest_blog_post_response = await latest_blog_post_res.json()
  const latest_blog_post = latest_blog_post_response.data

  return {
    props: {
      config: config,
      menu: menu,
      item: page,
      featured_products: featured_products,
      featured_taxonomies: featured_taxonomies,
      sliders: sliders,
      latest_blog_post: latest_blog_post
    },
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 10 seconds
    revalidate: 600, // In seconds

  }
}


export default Home
