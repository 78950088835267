import Head from "next/head";

function strip(html) {
    var one = html.replace(/<\/?[^>]+(>|$)/gm, "");
    var two = one.replace(/[\r\n]\s*[\r\n]/gm, "");
    return two;
}

const OrgSchema = ({ config }) => {

    return (
        <Head>
            <script type="application/ld+json">
                {`
                    {
                        "@context": "http://schema.org/",
                        "@type": "LocalBusiness",
                        "name": "${config.company_name}",
                        "image": "${process.env.NEXT_PUBLIC_LOGO_IMAGE}"
                    }
                `}
            </script>
        </Head>
    );
};
export default OrgSchema;





