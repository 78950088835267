import React, { useRef, useEffect } from 'react';

function Scripts(javascripts) {

    const contentRef = useRef();

    useEffect(() => {
        if (javascripts && javascripts.javascripts) {
            const fragment = document.createRange().createContextualFragment(
                ` <div>${javascripts.javascripts}</div>`, // a.content is html string including script tag
            );
            if (contentRef.current) {
                contentRef.current.appendChild(fragment);
            } else {
                return;
            }
        }
    });
    return (
        <div ref={contentRef} />
    );

}

export default Scripts;


